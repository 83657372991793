export default function initialState() {
  return {
    order: [],
    imageTag: '',
    housePhotoUrl: '',
    houseLocalizedSummary: '',
    orderGuestNum: '',
    orderStayDuration: '',
    userExRate: '',
    orderBillingAddress: {},
    billingAddressData: {},
    canCheckout: true,
  }
}
