import { Card, Stripe, PaymentElement } from '../components/checkout/vue-stripe-elements-plus';
import CouponStoreCredit from '../components/checkout/payment/coupon_store_credit';

export default {
  components: {
    Card,
    PaymentElement,
    CouponStoreCredit
  },
  data: function() {
    return {
      additionalData: {
        name: undefined,
        address_line1: undefined,
        address_line2: undefined,
        address_city: undefined,
        address_state: undefined,
        address_zip: undefined,
        address_country: undefined
      },
      fincode3dsData: {
        home_phone_no: undefined,
        mobile_phone_no: undefined,
        work_phone_no: undefined,
        email: undefined,
        cvc: undefined,
        card_number: undefined,
        exp_month: undefined,
        exp_year: undefined
      },
      paymentCollectionMethod: undefined,
      completed: false,
      loading: false,
      updatingCardNumber: false,
      stripeOptions: {
        // see https://stripe.com/docs/stripe.js#element-options for details
        base: {
          fontWeight: 500,
          fontFamily: "Inter UI, Open Sans, Segoe UI, sans-serif",
          fontSize: "16px",
          iconColor: "#555",
          "::placeholder": {
            color: "#CFD7DF"
          },
          ":-webkit-autofill": {
            color: "#fce883"
          }
        },
        invalid: {
          iconColor: "#f55753",
          color: "#f55753"
        },
        hidePostalCode: true
      },
      clientSecret: '',
    }
  },
  computed: {
    rooms: function(){
    },
    canCheckout: function() {
      return this.$store.state.canCheckout
    }
  },
  watch: {
    'fincode3dsData.card_number': function(newValue, oldValue) {
      if (!this.updatingCardNumber) {
        this.handleCardNumberChange(newValue);
      }
    },
    '$store.state.order': function(newOrder) {
      if(newOrder.stripe_client_secret && newOrder.stripe_client_secret !== this.clientSecret) {
        this.clientSecret = newOrder.stripe_client_secret
      }
    },
    '$store.state.billingAddressData': function(billingAddressData) {
      if (!!billingAddressData) {
        this.additionalData.address_line1 = billingAddressData.line1;
        this.additionalData.address_line2 = billingAddressData.line2;
        this.additionalData.address_city = billingAddressData.city;
        this.additionalData.address_state = billingAddressData.state;
        this.additionalData.address_zip = billingAddressData.zip;
        this.additionalData.address_country = billingAddressData.country;
      }
    },
  },
  methods: {
    checkForm: function(e){
    },
    validates(){
      if (this.paymentCollectionMethod != 'hotel_collect' && !this.additionalData.name) {
        window.notification(I18n.t('airhost.checkout.payment.name_on_card_blank_error'))
        return false
      }

      if (this.paymentCollectionMethod != 'hotel_collect') {
        let payment_type = this.$refs.form.querySelector("input[id$='payment_method_type']").value
        if (payment_type=='fincode') {
          if (!this.fincode3dsData.cvc) {
            window.notification(I18n.t('airhost.checkout.payment.cvc_blank_error'))
            return false
          }

          if (!this.fincode3dsData.card_number) {
            window.notification(I18n.t('airhost.checkout.payment.card_number_blank_error'))
            return false
          }

          if (!this.$refs.form.querySelector("select[id$='airhost_order_payments_attributes_0_source_attributes_month']").value) {
            window.notification(I18n.t('airhost.checkout.payment.exp_month_blank_error'))
            return false
          }

          if (!this.$refs.form.querySelector("select[id$='airhost_order_payments_attributes_0_source_attributes_year']").value) {
            window.notification(I18n.t('airhost.checkout.payment.exp_year_blank_error'))
            return false
          }

          let fincode_allow_3ds2 = this.$refs.form.querySelector("input[id$='fincode_allow_3ds2']").value
          if (fincode_allow_3ds2 == 'true') {
            if (!this.fincode3dsData.mobile_phone_no && !this.fincode3dsData.email) {
              window.notification(I18n.t('airhost.checkout.payment.fincode_3ds2_input_blank'))
              return false
            }

            if (this.fincode3dsData.email) {
              const emailPattern = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
              if(!emailPattern.test(this.fincode3dsData.email)) {
                window.notification(I18n.t('airhost.checkout.payment.email_invalid'))
                return false
              }
            }
          }
        }
      }
      return true
    },
    pay: async function(e) {
      if (!this.canCheckout) {
        e.preventDefault()
        return
      }

      if (!this.validates()){
        e.preventDefault()
        return
      }

      this.completed = true
      this.loading = true
      $('.book-now-container').addClass('loading')
      e.preventDefault()
      if (this.paymentCollectionMethod == 'hotel_collect') {
        this.submit()
      } else {
        let payment_type = this.$refs.form.querySelector("input[id$='payment_method_type']").value
        let requireStripeSca = this.$refs.form.querySelector("input[id$='require_stripe_sca']").value

        if (payment_type=='fincode') {
          let form = this.$refs.form
          let card_no = form.querySelector("input[id$='airhost_order_payments_attributes_0_source_attributes_number']").value
          card_no = card_no.replace(/\s+/g, '')
          let exp_year = form.querySelector("select[id$='airhost_order_payments_attributes_0_source_attributes_year']").value.toString()
          let exp_month = form.querySelector("select[id$='airhost_order_payments_attributes_0_source_attributes_month']").value.toString()
          if (exp_month.length === 1) {
            exp_month = '0' + exp_month
          }

          const card = {
            card_no: card_no,
            expire: exp_year + exp_month,
            holder_name: form.querySelector("input[id$='airhost_order_payments_attributes_0_source_attributes_name']").value,
            security_code: form.querySelector("input[id$='airhost_order_payments_attributes_0_source_attributes_verification_value']").value
          }
          const _this = this
          let fincode = Fincode(form.querySelector("input[id$='fincode_public_key']").value)
          fincode.setTenantShopId(form.querySelector("input[id$='fincode_tenant_id']").value)
          fincode.tokens(card,
            function (status, response) {
              if (200 === status) {
                _this.submit(() => {
                  let form = _this.$refs.form
                  form.querySelector("input[id$='airhost_order_payments_attributes_0_source_attributes_gateway_payment_profile_id']").value = response.list[0].token
                  form.querySelector("input[id$='airhost_order_payments_attributes_0_source_attributes_number']").value = null
                  form.querySelector("select[id$='airhost_order_payments_attributes_0_source_attributes_month']").value = ''
                  form.querySelector("select[id$='airhost_order_payments_attributes_0_source_attributes_year']").value = ''
                  form.querySelector("input[id$='airhost_order_payments_attributes_0_source_attributes_name']").value = null
                  form.querySelector("input[id$='airhost_order_payments_attributes_0_source_attributes_verification_value']").value = null
                });
              } else {
                window.notification(response.errors[0].error_message)
                $('.book-now-container').removeClass('loading')
              }
            },
            function () {
              window.notification("Please check your network.")
              $('.book-now-container').removeClass('loading')
            }
          );
        } else if (requireStripeSca == '1') {
          const { error: submitError } = await Stripe.elements.submit()

          if (submitError) {
            window.notification(submitError.message)
            $('.book-now-container').removeClass('loading')
            return
          }

          if (!!window.sessionStorage) {
            window.sessionStorage.removeItem('checkout.payment.last_payment_error')
          }

          let stripeScaType = this.$refs.form.querySelector("input[id$='stripe_sca_type']").value
          return this.stripeScaSubmit(() => {
            // information updated. now we confirm payment
            // confirm payment
            let confirmHnd = stripeScaType === 'setup_intent' ? Stripe.instance.confirmSetup : Stripe.instance.confirmPayment
            let promise = confirmHnd({
              elements: Stripe.elements,
              confirmParams: {
                payment_method_data: {
                  billing_details: {
                    name: this.additionalData.name,
                    address: {
                      line1: this.additionalData.address_line1,
                      line2: this.additionalData.address_line2,
                      city: this.additionalData.address_city,
                      state: this.additionalData.address_state,
                      postal_code: this.additionalData.address_zip,
                      country: this.additionalData.address_country
                    }
                  }
                },
                return_url: window.location.href,
              }
            })

            promise.then(res => {
              if (res.error) {
                console.error(res.error)
                if (!!window.sessionStorage) {
                  window.sessionStorage.setItem('checkout.payment.last_payment_error', res.error.message)
                }
                window.location.reload()
              }
            })
          }, (err) => {
            window.notification(I18n.t('error'), 'danger')
            $('.book-now-container').removeClass('loading')
            console.error(err)
            window.location.reload()
          })
        } else {
          // createToken returns a Promise which resolves in a result object with
          // either a token or an error key.
          // See https://stripe.com/docs/api#tokens for the token object.
          // See https://stripe.com/docs/api#errors for the error object.
          // More general https://stripe.com/docs/stripe.js#stripe-create-token.
          Stripe.createToken(this.additionalData).then(data => {
            if (data.hasOwnProperty('error')) {
              // error handling for stripe errors
              window.notification(data.error.message)
              $('.book-now-container').removeClass('loading')
            } else {
              this.submit(() => {
                let form = this.$refs.form
                form.querySelector("input[id$='gateway_payment_profile_id']").value = data.token.id
                form.querySelector("input[id$='preferred_card_id']").value = data.token.card.id
                form.querySelector("input[id$='preferred_provider_data']").value = JSON.stringify(data.token)
              })
            }
          })
        }
      }
    },
    submit: function (beforeSend) {
      beforeSend && beforeSend()
      sessionStorage.removeItem('storeState') // remove vuex store cache from session. used in house#show
      sessionStorage.removeItem('showPageURL') // remove show page url cache from session. used in house#show
      this.$refs.form.submit()
    },
    stripeScaSubmit: function (resolve, reject) {
      sessionStorage.removeItem('storeState') // remove vuex store cache from session. used in house#show
      sessionStorage.removeItem('showPageURL') // remove show page url cache from session. used in house#show

      let formDom = $(this.$refs.form)
      $.ajax({
        url: formDom.attr('action'),
        data: formDom.serialize(),
        type: 'PATCH',
        success: function (data) {
          resolve(data)
        },
        error: function (err) {
          reject(err)
        },
      })
    },
    handleCardNumberChange: function(new_value) {
      this.updatingCardNumber = true;
      this.fincode3dsData.card_number = new_value.replace(/(\s|\/)/g, '').replace(/(.{4})/g, '$1 ').trim()
      this.updatingCardNumber = false;
    }
  },
  mounted: function() {
    const paymentCollectionMethodElement = document.querySelector('[data-payment-collection-method]')
    if (paymentCollectionMethodElement) {
      this.paymentCollectionMethod = this.paymentCollectionMethod || paymentCollectionMethodElement.getAttribute('data-payment-collection-method')
    }
    const client_secret_from_server = this.$refs.form.querySelector("input[id$='client_secret_from_server']").value
    if (client_secret_from_server) {
      this.clientSecret = client_secret_from_server
    }
  },
  beforeMount: function(){
  }
}