<template>
    <stripe-element
      ref='element'
      type='payment'
      :stripe='stripe'
      :value='value'
      :optionsForElements='{
        clientSecret: clientSecret,
        appearance: appearance,
      }'
      :options="{
        wallets: { applePay: 'never', googlePay: 'never' }
      }"
      @blur='$emit("blur")'
      @focus='$emit("focus")'
      @change='$emit("change", $event)'
  />
</template>

<script>
import props from './props'
import StripeElement from './StripeElement'

const APPEARANCE = {
    labels: 'floating',
    rules: {
    '.Label': {
      opacity: .5
    },
    '.Label--resting': {
      fontSize: '14px',
    },
    '.Label--floating': {
      opacity: .5,
    },
    '.Input': {
      color: '#495057',
          fontSize: '14px'
    },
    '.Input:focus': {
      borderColor: 'rgb(230, 230, 230)',
          boxShadow: 'none',
    },
    '.Input--invalid': {
      border: '1px solid var(--colorDanger)',
          boxShadow: 'none',
    },
    '.Input--invalid:focus': {
      border: '1px solid var(--colorDanger)',
          boxShadow: 'none',
    }
  }
}

export default {
  props,
  components: { StripeElement },
  data() {
    return {
      appearance: APPEARANCE
    }
  },
  methods: {
    blur () { this.$refs.element.blur() },
    clear () { this.$refs.element.clear() },
    focus () { this.$refs.element.focus() },
    update (options) { this.$refs.element.update(options) }
  }
}
</script>
